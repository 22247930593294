import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { Layout, FooterContact, Form, FormStep, FormHeading, Input, FormFooter, Button, TextArea } from '../components'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { API_ENDPOINT } from '../constants'
import SuccessIcon from '../img/success.svg'

type ContactPageTemplateProps = {
	title: string
}

export type FormData = {
	firstName: string
	surname: string
	emailAddress: string
	contactNumber?: string
	message: string
}

const ContactPageTemplate: React.FC<ContactPageTemplateProps> = ({ title }) => {
	const { register, handleSubmit, errors, formState, triggerValidation } = useForm<FormData>()

	const [step, setStep] = useState<number>(1)
	const [submitting, setSubmitting] = useState<boolean>(false)

	useEffect(() => {
		window.scrollTo({ top: 0 })
	}, [step])

	const onSubmit = handleSubmit(async (data) => {
		setSubmitting(true)

		try {
			await axios.post(`${API_ENDPOINT}/contact`, data)
		} catch (e) {
			console.log(e)
		} finally {
			setSubmitting(false)
		}

		setStep(2)
	})

	const setStatus = (fieldName: keyof FormData) => {
		if (errors[fieldName]) {
			return 'error'
		}

		if (formState.touched[fieldName] && errors[fieldName]) {
			return 'error'
		}

		if (formState.touched[fieldName]) {
			return 'successful'
		}
	}

	return (
		<div>
			<Form onSubmit={onSubmit}>
				<FormStep visible={step === 1}>
					<FormHeading
						p={
							<>
								<p>
									Whether you are an insurance intermediary and would like to use the services of{' '}
									<strong>Insure Anytime</strong> or would just like to share some feedback, drop us a note.
								</p>
							</>
						}
					>
						We'd love to hear from you!
					</FormHeading>
					<Grid>
						<Input
							id={'firstName'}
							name={'firstName'}
							label={'First Name*'}
							placeholder={'First name'}
							title={'First name'}
							inputRef={register({ required: true })}
							message={errors.firstName && 'Please enter field'}
							status={setStatus('firstName')}
							onChange={() => triggerValidation('firstName')}
							onBlur={() => triggerValidation('firstName')}
						/>
						<Input
							id={'surname'}
							name={'surname'}
							label={'Surname*'}
							placeholder={'Surname'}
							title={'Surname'}
							inputRef={register({ required: true })}
							message={errors.surname && 'Please enter field'}
							status={setStatus('surname')}
							onChange={() => triggerValidation('surname')}
							onBlur={() => triggerValidation('surname')}
						/>
						<Input
							id={'email'}
							name={'emailAddress'}
							label={'Email Address*'}
							placeholder={'Email Address'}
							title={'Email address'}
							type={'email'}
							inputRef={register({
								pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
								required: true,
							})}
							message={errors.emailAddress && 'Please enter field'}
							status={setStatus('emailAddress')}
							onChange={() => triggerValidation('emailAddress')}
							onBlur={() => triggerValidation('emailAddress')}
						/>
						<Input
							id={'number'}
							name={'contactNumber'}
							label={'Contact Number'}
							placeholder={'+1'}
							title={'Contact number'}
							inputRef={register({ pattern: /[0-9 ]+/ })}
							message={errors.contactNumber && 'Please enter field'}
							status={setStatus('contactNumber')}
							onChange={() => triggerValidation('contactNumber')}
							onBlur={() => triggerValidation('contactNumber')}
						/>
						<TextArea
							id={'message'}
							name={'message'}
							label={'Message*'}
							placeholder={'Enter message'}
							title={'Enter message'}
							inputRef={register({ required: true })}
							message={errors.message && 'Please enter field'}
							status={setStatus('message')}
							onChange={() => triggerValidation('message')}
							onBlur={() => triggerValidation('message')}
						/>
					</Grid>
					<FormFooter>
						<Button
							type='submit'
							variant='secondary'
							displayBlockOnMobile
							fixedSize={true}
							disabled={submitting}
							id='gtm-contact-submit'
						>
							{submitting ? 'Submitting...' : 'Submit'}
						</Button>
					</FormFooter>
				</FormStep>
				<FormStep visible={step === 2}>
					<Grid>
						<Row center='xs'>
							<Col>
								<SuccessIcon style={{ marginBottom: '1rem', marginTop: '1rem' }} />
							</Col>
						</Row>
					</Grid>
					<FormHeading
						subheading='Thank you.'
						center
						p={
							<>
								Your message has been received.
								<br />
								We will try and get back to you as soon as possible.
							</>
						}
					>
						Message Submitted
					</FormHeading>
					<Grid>
						<Row center='xs'>
							<Col>
								<Button variant='secondary' type='button' fixedSize={true} as={Link} to={'/'} id='gtm-contact-finish'>
									Finish
								</Button>
							</Col>
						</Row>
					</Grid>
				</FormStep>
			</Form>
		</div>
	)
}

type ContactPageProps = {
	data: {
		markdownRemark: {
			frontmatter: ContactPageTemplateProps
		}
	}
}

const ContactPage: React.FC<ContactPageProps> = ({
	data: {
		markdownRemark: { frontmatter },
	},
}) => {
	return (
		<Layout showRenewLinkOnMobile padMain={false} showFooterContact>
			<ContactPageTemplate {...frontmatter} />
		</Layout>
	)
}

const pageQuery = graphql`
	query ContactPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
			frontmatter {
				title
			}
		}
	}
`

export default ContactPage
export { ContactPageTemplate, pageQuery }
